import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Popconfirm, Row, Select } from "antd";
import { Table, Tag } from "antd";
import { Button, Collapse, Modal, Input, InputNumber, Checkbox, DatePicker, Space } from "antd";
import "./consultant.scss";
import ConsultantModal from "./ConsultantModal";
import { setConsultation, getSingleDataEntry, getSingleDataEntrySuccess } from "../../modules/actions/consultationActions";
import Loader from "../../Layout/Loader";

const { Column, ColumnGroup } = Table;

function Consultant(props) {
	const [entryNo, setEntryNo] = useState("");
	const [consultantModal, setConsultantModal] = useState(false);
	const [consultantOrNoDes, setConsultantOrNoDes] = useState(false);

	const [editObj, setEditObj] = useState(false);
	const [consultationObj, setConsultationObj] = useState("");

	const [searchh, setSearch] = useState(false);

	const [consultationEdit, setConsultationEdit] = useState(false);
	const [index, setIndex] = useState("");

	const search = () => {
		setSearch(true);
		props.getSingleDataEntry(entryNo, props.permission.branchCode);
	};

	const enterSearch = (e) => {
		if (e.keyCode === 13) {
			search();
		}
	};

	useEffect(()=>{
console.log("helooooo props.entry checking", props.entry);
	},[])

	const url = new URL(window.location);
	useEffect(() => {
		let number = url.searchParams.get("entryNo")
		// console.log(number)
		// props.getSingleDataEntrySuccess(false)
		if (number) {
			props.getSingleDataEntry(number, props.permission.branchCode);
			setEntryNo(number)
			setSearch(true)
		}
		// if (url.searchParams.get("entryNo") && !entryNo) {
		// (url.searchParams.get("entryNo";
		// console.log(url.searchParams.get("entryNo"))
		// search();
		// }
	}, []);

	const addNoDecision = () => {
		let preItemIndex = editObj.itemIndex ? editObj.itemIndex : 0;
		let obj2 = {
			itemIndex: preItemIndex,
			color: editObj.color,
			new: true,
			consultationDetailColor: "",
			consultationDetailConclusion: "",
			consultationDetailCut: "",
			consultationDetailMeas1: "",
			consultationDetailMeas2: "",
			consultationDetailMeas3: "",
			consultationDetailShape: "",
			consultationDetailSpecies: "",
			consultationDetailTreatment: "",
			consultationDetailVariety: "",
			consultationDetailWeight: "",
			goldTestingRate: 0,
			item: editObj.item,
			jewelleryItems: editObj.jewelleryItems,
			mineRate: 0,
			quantity: editObj.quantity,
			sealRate: 0,
			selectstoneValue: editObj.selectstoneValue,
			service: "No Decision",
			serviceRate: 0,
			urgentRate: 0,
			weight: editObj.weight,
			weightDescription: editObj.weightDescription,
			weightRange: editObj.weightRange,
			relatedEntryNo: editObj.relatedEntryNo,
			entryNo: editObj.entryNo,
			id: editObj.id
		};
		let wholeData = props.entry;
		wholeData.consultationArray = props.entry.consultationArray.length
			? [...props.entry.consultationArray]
			: [...props.entry.items];
		for (let i = 0; i < wholeData.consultationArray.length; i++) {
			if (wholeData.consultationArray[i].itemIndex === preItemIndex) {
				wholeData.consultationArray[i] = obj2;
			}
		}

		props.setConsultation(
			wholeData,
			"Successfully made a no decision.",
			clearState,
			props.permission.branchCode
		);
	};

	const clearState = (push) => {
		if (push && props.entry.service == "No Decision") {
			props.history.push(`/take-out-items?entryNo=${props.entry.entryNo}`);
		}
		setConsultantModal(false);
		setConsultantOrNoDes(false);
		setEditObj(false);
		setConsultationObj("");
	};

	const consultationTrue = (a) => {
		if (a.service === "Consultation") {
			setConsultationEdit(true);
			setConsultantModal(true);
		} else {
			setConsultantModal(true);
		}
	};


	// useEffect(() => {
	// 	console.log(props.entry, searchh, props.entry.deliveryObj)
	// }, [props.entry, searchh, props.entry.deliveryObj])

	return (
		<div className="Consultant">
			<div className="searchDiv">
				<Row className="spaceBetween">
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<div className="selectCustomer">
							<Input
								className="select"
								size="large"
								showSearch
								placeholder="Entry No."
								value={entryNo}
								onChange={(ev) => setEntryNo(ev.target.value)}
								onKeyUp={(e) => enterSearch(e)}
								autoFocus
								suffix={
									<>
										{props.entry.entryNo && (
											<Button
												type="primary"
												onClick={() =>
													props.history.push(`/take-out-items?entryNo=${props.entry.entryNo}`)
												}
												style={{ background: "#001529", borderColor: "#001529", marginRight: 5 }}
											>
												Return To Slip
											</Button>
										)}
										<Button
											type="primary"
											onClick={search}
											style={{ background: "#001529", borderColor: "#001529", marginRight: 5 }}
										>
											Search
										</Button>
										<Button
											type="primary"
											onClick={() => {
												setSearch(false);
												setEntryNo("");
											}}
											style={{ background: "#f50057", borderColor: "#f50057" }}
										>
											Clear
										</Button>
									</>
								}
							/>
						</div>
					</Col>
				</Row>
			</div>

			{props.entry && searchh && !props.entry.deliveryObj ? (
				
				<div className="innerCons">
					<h4 className="innerConHead">Selected Reciept Details</h4>
					<>
						<Table
						pagination={false}
							className="itemsTable"
							dataSource={
								props.entry.consultationArray
									? props.entry.consultationArray.length
										? props.entry.consultationArray
										: props.entry.items
									: props.entry.items
							}
						>
							<Column title="S No" render={(text, record, index) => <>{index + 1}</>} />
							<Column title="Service" dataIndex="service" key="service" />
							<Column
								title="Color"
								dataIndex="color"
								key="color"
								render={(text, record, index) =>
									record.consultationDetailColor ? record.consultationDetailColor : record.color
								}
							/>
							<Column
								title="Weight"
								render={(text, record, index) => (
									<>
										{record.consultationDetailWeight
											? record.consultationDetailWeight
											: `${record.weight} ${record.weightDescription}`}
									</>
								)}
							/>
							<Column
								title="Amount"
								dataIndex="serviceRate"
								key="serviceRate"
								render={(text, record, index) => {
									let { sealRate, mineRate, urgentRate, goldTestingRate, serviceRate } = record ? record :props.formData.LoseStoneService[2].price ;
									let ItemTotal =
										Number(sealRate) +
										Number(mineRate) +
										Number(urgentRate) +
										Number(goldTestingRate) +
										Number(serviceRate);

									return <>{ItemTotal}</>;
								}}
							/>
							<Column
								title="Action"
								render={(text, record, index) => (
									<>
										<Button
											onClick={() => {
												setConsultantOrNoDes(true);
												setEditObj(record);
												setIndex(index);
											}}
										>
											Edit
										</Button>
									</>
								)}
							/>
						</Table>
						<ConsultantModal
							clearState={clearState}
							consultantModal={consultantModal}
							setConsultantModal={setConsultantModal}
							setConsultationObj={setConsultationObj}
							entryNo={entryNo}
							consultationObj={consultationObj}
							editObj={editObj}
							setConsultationEdit={setConsultationEdit}
							consultationEdit={consultationEdit}
							entry={props.entry}
							index={index}
						/>
						<Modal
							centered
							title="Consultant or No decision"
							className="consModal"
							visible={consultantOrNoDes}
							okButtonProps={{ style: { display: "none" } }}
							onCancel={() => {
								setConsultantOrNoDes(false);
								setEditObj(false);
							}}
							cancelText="Close"
							cancelButtonProps={{
								style: { background: "#f50057", color: "white", borderColor: "#f50057" }
							}}
						>
							<div className="centerAlign">
								<div className="buttonDivCon">
									<Button
										style={{ background: "#001529", color: "white", borderColor: "#001529" }}
										onClick={() => consultationTrue(editObj)}
									>
										Consultation
									</Button>

									<Popconfirm
										title="Are You Sure You Want To Make No Decision ?"
										okText="Yes"
										cancelText="No"
										okButtonProps={{
											style: { background: "#001529", color: "white", borderColor: "#001529" }
										}}
										cancelButtonProps={{
											style: { background: "#f50057", color: "white", borderColor: "#f50057" }
										}}
										onConfirm={(a) => addNoDecision(a)}
									>
										<Button
											style={{ background: "#001529", color: "white", borderColor: "#001529" }}
										>
											No Decision
										</Button>
									</Popconfirm>
								</div>
							</div>
						</Modal>
					</>
				</div>
			) : props.entry && searchh && props.entry.deliveryObj ? (
				<h3 className="alreadyDelivered">Already Delivered</h3>
			) : !props.entry && searchh ? (
				<h3 className="alreadyDelivered">No entry found related to this entry number.</h3>
			) : null}
			{props.isLoading && <Loader />}
		</div>
	);
}

const mapStateToProps = (store) => {
	return {
		entry: store.consultation.singleEntry,
		isLoading: store.consultation.consultationLoading,
		permission: store.users.permission,
		formData: store.FormData,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getSingleDataEntry,
			setConsultation,
			getSingleDataEntrySuccess
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(Consultant);
