import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import { Col, Row, Select, Input } from "antd";
import { ConsultantDetails } from "./ConsultantDetails";
import {
	addConsultation,
	setConsultation,
	updateConsultation
} from "../../modules/actions/consultationActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
const { Option } = Select;

const ConsultantModal = (props) => {
	const [color, setColor] = useState("");
	const [weight, setWeight] = useState("");
	const [meas1, setMeas1] = useState("");
	const [meas2, setMeas2] = useState("");
	const [meas3, setMeas3] = useState("");
	const [shape, setShape] = useState("");
	const [cut, setCut] = useState("");
	const [treatement, setTreatement] = useState("");
	const [conclusion, setConclusion] = useState("");
	const [variety, setVariety] = useState("");
	const [species, setSpecies] = useState("");
	const [weightDes, setWeightDes] = useState();

	const [consultantObj, setConsultantObj] = useState({});

	useEffect(() => {
		if (!weightDes) {
			setWeightDes("ct");
		}

		// console.log(props, "props.consultationEdit");
	}, []);

	useEffect(() =>  {
console.log(props.formData , "const");

	},[props.formData])

	useEffect(() => {
		setConsultantObj({
			color: color,
			weight: weight,
			meas1: meas1,
			meas2: meas2,
			meas3: meas3,
			shape: shape,
			cut: cut,
			treatement: treatement,
			conclusion: conclusion,
			variety: variety,
			species: species,
			entryNo: props.entryNo,
			weightDes: weightDes ? weightDes : "ct"
		});
	}, [
		color,
		weight,
		meas1,
		meas2,
		meas3,
		shape,
		cut,
		treatement,
		conclusion,
		variety,
		species,
		weightDes
	]);

	const [isModalVisible, setIsModalVisible] = useState(false);
	const openModal = () => {
		setIsModalVisible(!isModalVisible);
	};
	useEffect(() => {
		if (props.entry.consultationArray && props.entry.consultationArray.length > 0) {
			let Total = 0;
			for (let index = 0; index < props.entry.consultationArray.length; index++) {
				let {
					sealRate,
					mineRate,
					urgentRate,
					goldTestingRate,
					serviceRate
				} = props.entry.consultationArray[index];
				let ItemTotal =
					Number(sealRate) +
					Number(mineRate) +
					Number(urgentRate) +
					Number(goldTestingRate) +
					Number(serviceRate);
				Total += ItemTotal;
			}
			props.entry.totalAmount = Total;
		}
	}, [props.entry.consultationArray]);

	const showModal = (bool) => {
		let obj = {};
		if (bool) {
			obj = {
				itemIndex: props.editObj.itemIndex,
				color: props.editObj.color,
				new: true,
				consultationDetailColor: color,
				consultationDetailConclusion: conclusion,
				consultationDetailCut: cut,
				consultationDetailMeas1: meas1,
				consultationDetailMeas2: meas2,
				consultationDetailMeas3: meas3,
				consultationDetailShape: shape,
				consultationDetailSpecies: species,
				consultationDetailTreatment: treatement,
				consultationDetailVariety: variety,
				consultationDetailWeight: `${weight} ${weightDes ? weightDes : "ct"}`,
				goldTestingRate: props.editObj.goldTestingRate,
				item: props.editObj.item,
				jewelleryItems: props.editObj.jewelleryItems,
				mineRate: props.editObj.mineRate,
				quantity: props.editObj.quantity,
				sealRate: props.editObj.sealRate,
				selectstoneValue: props.editObj.selectstoneValue,
				service:
					props.editObj.service == "CVD Testing (Lots)" ? "CVD Testing (Lots)" : "Consultation",
				serviceRate:
					props.editObj.service == "CVD Testing (Lots)" ? props.editObj.serviceRate : props.formData.LoseStoneService[2].price,
				urgentRate: props.editObj.urgentRate,
				weight: props.editObj.weight,
				weightDescription: props.editObj.weightDescription,
				weightRange: props.editObj.weightRange,
				relatedEntryNo: props.editObj.relatedEntryNo,
				entryNo: props.editObj.entryNo,
				id: props.editObj.id
			};
		} else {
			obj = {
				itemIndex: props.editObj.itemIndex,
				color: props.editObj.color,
				new: true,
				consultationDetailColor: color,
				consultationDetailConclusion: conclusion,
				consultationDetailCut: cut,
				consultationDetailMeas1: meas1,
				consultationDetailMeas2: meas2,
				consultationDetailMeas3: meas3,
				consultationDetailShape: shape,
				consultationDetailSpecies: species,
				consultationDetailTreatment: treatement,
				consultationDetailVariety: variety,
				consultationDetailWeight: `${weight} ${weightDes ? weightDes : "ct"}`,
				goldTestingRate: props.editObj.goldTestingRate,
				item: props.editObj.item,
				jewelleryItems: props.editObj.jewelleryItems,
				mineRate: props.editObj.mineRate,
				quantity: props.editObj.quantity,
				sealRate: props.editObj.sealRate,
				selectstoneValue: props.editObj.selectstoneValue,
				service:
					props.editObj.service == "CVD Testing (Lots)" ? "CVD Testing (Lots)" : "Consultation",
				serviceRate:
					props.editObj.service == "CVD Testing (Lots)" ? props.editObj.serviceRate : props.formData.LoseStoneService[2].price,
				urgentRate: props.editObj.urgentRate,
				weight: props.editObj.weight,
				weightDescription: props.editObj.weightDescription,
				weightRange: props.editObj.weightRange,
				id: props.editObj.id
			};
		}
		const wholeData = props.entry;

		const itemList = [];
		props.entry.items.map((a, i) => {
			delete a.id;
			delete a.relatedEntryNo;
			itemList.push(a);
		});

		wholeData.consultationArray = props.entry.consultationArray.length
			? [...props.entry.consultationArray]
			: [...itemList];
		for (let i = 0; i < wholeData.consultationArray.length; i++) {
			if (wholeData.consultationArray[i].itemIndex === props.editObj.itemIndex) {
				wholeData.consultationArray[i] = obj;
				// delete wholeData.consultationArray[i].relatedEntryNo
			}
		}
		// delete wholeData.id

		// consutationArr.splice(props.index,1)
		if (obj.consultationDetailColor) {
			props.setConsultation(
				wholeData,
				"Successfully Added Consultation",
				props.clearState,
				props.permission.branchCode,
				openModal,
				props.entry.entryNo
			);
		} else {
			alert("Color is Required")
		}
	};

	const updateAndShowModal = (bool) => {
		const preItemIndex = props.editObj.itemIndex ? props.editObj.itemIndex : 0;
		let obj = {};
		if (bool) {
			obj = {
				itemIndex: preItemIndex,
				color: props.editObj.color,
				new: true,
				consultationDetailColor: color,
				consultationDetailConclusion: conclusion,
				consultationDetailCut: cut,
				consultationDetailMeas1: meas1,
				consultationDetailMeas2: meas2,
				consultationDetailMeas3: meas3,
				consultationDetailShape: shape,
				consultationDetailSpecies: species,
				consultationDetailTreatment: treatement,
				consultationDetailVariety: variety,
				consultationDetailWeight: `${weight} ${weightDes ? weightDes : "ct"}`,
				goldTestingRate: props.editObj.goldTestingRate,
				item: props.editObj.item,
				jewelleryItems: props.editObj.jewelleryItems,
				mineRate: props.editObj.mineRate,
				quantity: props.editObj.quantity,
				sealRate: props.editObj.sealRate,
				selectstoneValue: props.editObj.selectstoneValue,
				service:
					props.editObj.service == "CVD Testing (Lots)" ? "CVD Testing (Lots)" : "Consultation",
				serviceRate:
					props.editObj.service == "CVD Testing (Lots)" ? props.editObj.serviceRate : props.formData.LoseStoneService[2].price,
				urgentRate: props.editObj.urgentRate,
				weight: props.editObj.weight,
				weightDescription: props.editObj.weightDescription,
				weightRange: props.editObj.weightRange,
				relatedEntryNo: props.editObj.relatedEntryNo,
				entryNo: props.editObj.entryNo,
				id: props.editObj.id
			};
		} else {
			obj = {
				itemIndex: preItemIndex,
				color: props.editObj.color,
				new: true,
				consultationDetailColor: color,
				consultationDetailConclusion: conclusion,
				consultationDetailCut: cut,
				consultationDetailMeas1: meas1,
				consultationDetailMeas2: meas2,
				consultationDetailMeas3: meas3,
				consultationDetailShape: shape,
				consultationDetailSpecies: species,
				consultationDetailTreatment: treatement,
				consultationDetailVariety: variety,
				consultationDetailWeight: `${weight} ${weightDes ? weightDes : "ct"}`,
				goldTestingRate: props.editObj.goldTestingRate,
				item: props.editObj.item,
				jewelleryItems: props.editObj.jewelleryItems,
				mineRate: props.editObj.mineRate,
				quantity: props.editObj.quantity,
				sealRate: props.editObj.sealRate,
				selectstoneValue: props.editObj.selectstoneValue,
				service:
					props.editObj.service == "CVD Testing (Lots)" ? "CVD Testing (Lots)" : "Consultation",
				serviceRate:
					props.editObj.service == "CVD Testing (Lots)" ? props.editObj.serviceRate : props.formData.LoseStoneService[2].price,
				urgentRate: props.editObj.urgentRate,
				weight: props.editObj.weight,
				weightDescription: props.editObj.weightDescription,
				weightRange: props.editObj.weightRange,
				// "relatedEntryNo": props.editObj.relatedEntryNo,
				entryNo: props.editObj.entryNo,
				id: props.editObj.id
			};
		}

		// console.log(obj, "obj obj");
		const wholeData = props.entry;
		const itemList = [];
		props.entry.items.map((a, i) => {
			delete a.id;
			delete a.relatedEntryNo;
			itemList.push(a);
		});
		wholeData.consultationArray = props.entry.consultationArray.length
			? [...props.entry.consultationArray]
			: [...itemList];
		for (let i = 0; i < wholeData.consultationArray.length; i++) {
			if (wholeData.consultationArray[i].itemIndex === preItemIndex) {
				wholeData.consultationArray[i] = obj;
			} else {
				if (bool) {
					wholeData.consultationArray[i].relatedEntryNo = wholeData.consultationArray[i].entryNo;
				}
			}
		}
		if (obj.consultationDetailColor) {
			props.setConsultation(
				wholeData,
				"Successfully Added Consultation",
				props.clearState,
				props.permission.branchCode,
				openModal
			);
		} else {
			alert("Color is Required")

		}
		// props.addConsultation(obj, setIsModalVisible)
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	useEffect(() => {
		if (props.consultationEdit) {
			setColor(props.editObj.consultationDetailColor);
			setWeight(
				props.editObj.consultationDetailWeight
					? Number(props.editObj.consultationDetailWeight.split(" ")[0])
					: ""
			);
			setMeas1(props.editObj.consultationDetailMeas1);
			setMeas2(props.editObj.consultationDetailMeas2);
			setMeas3(props.editObj.consultationDetailMeas3);
			setShape(props.editObj.consultationDetailShape);
			setCut(props.editObj.consultationDetailCut);
			setTreatement(props.editObj.consultationDetailTreatment);
			setConclusion(props.editObj.consultationDetailConclusion);
			setVariety(props.editObj.consultationDetailVariety);
			setSpecies(props.editObj.consultationDetailSpecies);
			setWeightDes(
				props.editObj.consultationDetailWeight
					? props.editObj.consultationDetailWeight.split(" ")[1]
					: ""
			);
		} else {
			setColor("");
			setWeight("");
			setMeas1("");
			setMeas2("");
			setMeas3("");
			setShape("");
			setCut("");
			setTreatement("");
			setConclusion("");
			setVariety("");
			setSpecies("");
		}
	}, [props.consultationEdit]);

	return (
		<>
			<Modal
				centered
				title="Consultant"
				className="consModal"
				okText={props.consultationEdit ? "Update & Save" : "Print & Save"}
				visible={props.consultantModal}
				// onOk={handleOk}
				onCancel={() => {
					props.setConsultantModal(false);
					props.setConsultationEdit(false);
				}}
				onOk={() => {
					if (props.editObj.service === "Consultation" || props.editObj.service === "No Decision") {
						if (
							props.editObj.hasOwnProperty("consultationDetailWeight") ||
							props.editObj.hasOwnProperty("consultationDetailColor") ||
							props.editObj.hasOwnProperty("consultationDetailConclusion") ||
							props.editObj.hasOwnProperty("consultationDetailCut") ||
							props.editObj.hasOwnProperty("consultationDetailMeas1") ||
							props.editObj.hasOwnProperty("consultationDetailMeas2") ||
							props.editObj.hasOwnProperty("consultationDetailMeas3") ||
							props.editObj.hasOwnProperty("consultationDetailShape") ||
							props.editObj.hasOwnProperty("consultationDetailSpecies") ||
							props.editObj.hasOwnProperty("consultationDetailTreatment") ||
							props.editObj.hasOwnProperty("consultationDetailVariety") ||
							props.editObj.hasOwnProperty("consultationDetailWeight")
						) {
							updateAndShowModal(true);
						} else {
							updateAndShowModal(false);
						}
					} else {
						if (
							props.editObj.hasOwnProperty("consultationDetailWeight") ||
							props.editObj.hasOwnProperty("consultationDetailColor") ||
							props.editObj.hasOwnProperty("consultationDetailConclusion") ||
							props.editObj.hasOwnProperty("consultationDetailCut") ||
							props.editObj.hasOwnProperty("consultationDetailMeas1") ||
							props.editObj.hasOwnProperty("consultationDetailMeas2") ||
							props.editObj.hasOwnProperty("consultationDetailMeas3") ||
							props.editObj.hasOwnProperty("consultationDetailShape") ||
							props.editObj.hasOwnProperty("consultationDetailSpecies") ||
							props.editObj.hasOwnProperty("consultationDetailTreatment") ||
							props.editObj.hasOwnProperty("consultationDetailVariety") ||
							props.editObj.hasOwnProperty("consultationDetailWeight")
						) {
							showModal(true);
						} else {
							showModal(false);
						}
					}
				}}
				okButtonProps={{ style: { background: "#001529", color: "white", borderColor: "#001529" } }}
				cancelButtonProps={{
					style: { background: "#f50057", color: "white", borderColor: "#f50057" }
				}}
			>
				<div className="consultForm">
					<Row className="spaceBetween">
						<Col xs={11} sm={11} md={11} lg={11} xl={19}>
							<div className="consultFormInput">
								<div className="inputFields">
									<label className="w100P">
										<h5>Color</h5>
										<Input value={color} onChange={(e) => setColor(e.target.value)} />
									</label>
								</div>
							</div>
						</Col>
						<Col xs={11} sm={11} md={11} lg={11} xl={19}>
							<div className="consultFormInput">
								<div className="inputFields">
									<label className="w100P">
										<h5>Weight</h5>
										<div className="consultattionWeightInput">
											<Input
												type="text"
												value={weight}
												onChange={(e) => setWeight(e.target.value)}
											/>
											<select
												value={weightDes}
												onChange={(ev) => setWeightDes(ev.target.value)}
												className="select-after"
											>
												<option value="ct">ct</option>
												<option value="g">g</option>
												<option value="kg">kg</option>
											</select>
										</div>
									</label>
								</div>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="consultFormInput">
								<div className="inputFields">
									<label className="w100P">
										<h5>Meas1</h5>
										<Input value={meas1} onChange={(e) => setMeas1(e.target.value)} />
									</label>
								</div>
							</div>
						</Col>
						<p>X</p>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="consultFormInput">
								<div className="inputFields">
									<label className="w100P">
										<h5>Meas2</h5>
										<Input value={meas2} onChange={(e) => setMeas2(e.target.value)} />
									</label>
								</div>
							</div>
						</Col>
						<p>X</p>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="consultFormInput">
								<div className="inputFields">
									<label className="w100P">
										<h5>Meas3</h5>
										<Input value={meas3} onChange={(e) => setMeas3(e.target.value)} />
									</label>
								</div>
							</div>
						</Col>

						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="consultFormInput">
								<div className="inputFields">
									<label className="w100P">
										<h5>Shape</h5>
										<Input value={shape} onChange={(e) => setShape(e.target.value)} />
									</label>
								</div>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="consultFormInput">
								<div className="inputFields">
									<label className="w100P">
										<h5>Cut</h5>
										<Input value={cut} onChange={(e) => setCut(e.target.value)} />
									</label>
								</div>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="consultFormInput">
								<div className="inputFields">
									<label className="w100P">
										<h5>Treatment</h5>
										<Input value={treatement} onChange={(e) => setTreatement(e.target.value)} />
									</label>
								</div>
							</div>
						</Col>

						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="consultFormInput">
								<div className="inputFields">
									<label className="w100P">
										<h5>Conclusion</h5>
										<Input value={conclusion} onChange={(e) => setConclusion(e.target.value)} />
									</label>
								</div>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="consultFormInput">
								<div className="inputFields">
									<label className="w100P">
										<h5>Variety</h5>
										<Input value={variety} onChange={(e) => setVariety(e.target.value)} />
									</label>
								</div>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="consultFormInput">
								<div className="inputFields">
									<label className="w100P">
										<h5>Species</h5>
										<Input value={species} onChange={(e) => setSpecies(e.target.value)} />
									</label>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</Modal>
			<ConsultantDetails
				isModalVisible={isModalVisible}
				setIsModalVisible={setIsModalVisible}
				showModal={showModal}
				handleOk={handleOk}
				handleCancel={handleCancel}
				consultantObj={consultantObj}
			/>
		</>
	);
};


const mapStateToProps = (store) => {
	return {
		entry: store.consultation.singleEntry,
		isLoading: store.consultation.consultationLoading,
		permission: store.users.permission,
		formData: store.FormData,

	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			addConsultation,
			updateConsultation,
			setConsultation
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantModal);
