import React, { useEffect, useState } from "react";
import { Modal, Button, Popconfirm } from "antd";
import { Table, Tag, Space, DatePicker, Col, Row, Select } from "antd";

import { connect } from "react-redux";
import {
  DeleteUnSeenFn,
  getSummaryReport,
  getUnSeenFn,
} from "../../../modules/actions/summaryReportsAction";
import { bindActionCreators } from "redux";
import ExpenseModal from "./ExpenseModal";
import EYE from "../../../assets/eye.svg";

const SummaryDaily = (props) => {
  let {
    isModalVisible,
    setIsModalVisible,
    showModal,
    handleOk,
    handleCancel,
    monthlyData,
    selectMonth,
    data,
    dataYear,
    selectedBranch,
    setDaysDataArr,
    currentMonthName,
  } = props;
  useEffect(() => {
    props.getUnSeenFn(selectedBranch);
  }, []);
  const checkFn = (check, timeStamp, dataObj, i) => {
    let date = new Date(timeStamp).toISOString().split("T")[0];
    if (dataObj.seen) {
      let obj = {
        date: date,
        dateMillisecond: timeStamp,
        datestr: new Date(timeStamp),
        seen: "false",
        branchCode: window.location.pathname.split("/")[5],
      };
      console.log(window.location.pathname.split("/")[5] , "your branch")
      // updateMonthlyData(data, check.toString())
      props.unSeenFn(
        obj,
        "days",
        dataYear,
        new Date(timeStamp).getMonth() + 1,
        window.location.pathname.split("/")[5],
        dataObj,
        check,
        updateMonthlyData,
        i
      );
    } else {
      let obj = {
        date: date,
        dateMillisecond: timeStamp,
        datestr: new Date(timeStamp),
        seen: "true",
        branchCode: window.location.pathname.split("/")[5],
      };
      console.log(props);
      // updateMonthlyData(data, check.toString())
      if (props.unSeenReports) {
        let filteredObj = props.unSeenReports.filter(
          (objF) => Number(objF.dateMillisecond) === timeStamp
        )[0];
        console.log(filteredObj, props.unSeenReports, timeStamp);
        if (filteredObj) {
          console.log("filteredObj if");
          props.DeleteUnSeenFn(
            filteredObj.id,
            obj,
            "days",
            dataYear,
            new Date(timeStamp).getMonth() + 1,
            window.location.pathname.split("/")[5],
            dataObj,
            check,
            updateMonthlyData,
            i
          );
        } else {
          console.log("filteredObj else");
          props.SeenFn(
            obj,
            "days",
            dataYear,
            new Date(timeStamp).getMonth() + 1,
            window.location.pathname.split("/")[5],
            dataObj,
            check,
            updateMonthlyData,
            i
          );
        }
      }
    }
  };

  const updateMonthlyData = (index, check, unSeen) => {
    let arr = [...monthlyData];

    if (unSeen) {
      arr.splice(index, 1, {
        ...arr[index],
        seen: check,
      });
    } else {
      arr.map((a, i) => {
        if (i <= index) {
          if (
            props.unSeenReports.filter(
              (objj) => Number(objj.dateMillisecond) === Number(a.timestamp)
            ).length
          ) {
            arr.splice(i, 1, {
              ...a,
              seen: false,
            });
          } else {
            arr.splice(i, 1, {
              ...a,
              seen: check,
            });
          }
        } else {
          arr.splice(i, 1, {
            ...a,
            seen: false,
          });
        }
      });
    }

    setDaysDataArr(arr);
  };
  const [all_expenses, setall_expenses] = useState([]);
  const [expenseamount, setexpenseamount] = useState(0);
  const [expenseModal, setexpenseModal] = useState(false);
  const handleOk2 = () => {
      setall_expenses([]);
      setexpenseamount(0);
    setexpenseModal(false);
  };

  const handleCancel2 = () => {
      setall_expenses([]);
      setexpenseamount(0);
    setexpenseModal(false);
  };
  return (
    // <Modal title={currentMonthName} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
    <div className="summarDetailDiv">
      <h1>
        Daily report month of {currentMonthName} {dataYear}
      </h1>
      <table className="" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>Checked</th>
            <th
              onClick={() => {
                console.log(monthlyData);
              }}
            >
              Date
            </th>
            <th>Income</th>
            <th>Membership</th>
            <th>Expense</th>
            <th>Profit/Loss</th>
          </tr>
        </thead>
        <tbody>
          {monthlyData &&
            monthlyData.map((a, i) => {
              return (
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={a.seen}
                      onChange={() => checkFn(!a.seen, a.timestamp, a, i)}
                    />
                  </th>
                  <th>{a.day}</th>
                  <td>{a.totalLaboratoryCashRecieved}</td>
                  <td>{a.totalLaboratoryMemberShipCollection}</td>
                  <td
                  className="expensetd"
                  style={{cursor:"pointer"}}
                    onClick={() => {
                      setall_expenses(a.all_expenses);
                      setexpenseamount(a.totalLaboratoryExpenses);
                      setexpenseModal(true);
                    }}
                  >
                    {a.totalLaboratoryExpenses} <img src={EYE}/>
                  </td>
                  <td>{a.laboratoryTotal}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <ExpenseModal
        TotalExpense={all_expenses}
        collectionData={{
          TotalExpenses: expenseamount,
        }}
        isModalVisible={expenseModal}
        handleOk={handleOk2}
        handleCancel={handleCancel2}
      />
    </div>

    // </Modal>
  );
};

const mapStateToProps = (store) => ({
  unSeenReports: store.summaryReports.unSeenReports,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // getMonthSummaryReport
      getSummaryReport,
      getUnSeenFn,
      DeleteUnSeenFn,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SummaryDaily);
