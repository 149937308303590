import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Alert, Col, Popconfirm, Row, Select } from "antd";
import {
  Button,
  Collapse,
  Modal,
  Input,
  InputNumber,
  Checkbox,
  DatePicker,
  Space,
} from "antd";
import "./takeout.scss";
import CustomerDetails from "./../TakeIn/customerDetail";
import { SettingOutlined, SaveOutlined } from "@ant-design/icons";
import Items from "./../TakeIn/entries";
import EntrieModal from "./../TakeIn/entrieModal";
import { Spin } from "antd";

import {
  SaveDeliveryDataEntry,
  clearSingleDataEntry,
  deleteDataItem,
  deleteDelivery,
  getSingleDataEntry,
  getSingleDataEntrySuccess,
  updateDataEntry,
  updateSingleItemCopy,
} from "../../modules/actions/dataEntryActions";
import AddAndViewExpense from "../TakeIn/addAndViewExpense";
import {
  activeOrNorActive,
  clearCustomer,
  filteredCustomer,
  getCustomer,
} from "../../modules/actions/customerActions";
import Loader from "../../Layout/Loader";
import AddItems from "../TakeIn/addItemModal";
import { dateFormat } from "../../common/common";
import moment from "moment";
import { checkUser } from "./../../modules/actions/authenticationActions";

const { Option } = Select;

function TakeOut(props) {
  const jumpPrintPage = (entryNo) => {
    props.history.push(`/Take-Out-Print/${entryNo}`);
    // window.location.href = (`/Take-Out-Print/${entryNo}`);
  };

  const [modal1Visible, setModal1Visible] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [modal3Visible, setModal3Visible] = useState(false);

  function setModal1VisibleFn(modal1Visible) {
    setModal1Visible(modal1Visible);
  }
  function setModal2VisibleFn(modal2Visible) {
    setModal2Visible(modal2Visible);
  }
  function setModal3VisibleFn(modal2Visible) {
    setModal3Visible(modal2Visible);
  }
  function addData(obj) {
    setItems([...items, obj]);
  }

  const [entryNo, setEntryNo] = useState("");

  const [items, setItems] = useState([]);
  const [comments, setComments] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [advance, setAdvance] = useState(0);
  const [preBalanceOrAdvance, setPreBalanceOrAdvance] = useState("");
  const [balance, setBalance] = useState("");
  const [discountRequest, setDiscountRequest] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountReason, setDiscountReason] = useState("");
  const [deliveryDate, setDeliveryDate] = useState(today);
  const [boxOrPacket, setBoxOrPacket] = useState("");
  const [boxOrPacketNo, setBoxOrPacketNo] = useState("");
  const [addedExpenses, setAddedExpenses] = useState([]);
  const [addedExpensesAmount, setAddedExpensesAmount] = useState(0);
  const [editObj, setEditObj] = useState("");
  const [index, setIndex] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [discountApproved, setDiscountApproved] = useState("");
  const [paymentRecieved, setPaymentRecieved] = useState(0);
  const [consultantBool, setConsultantBool] = useState(false);
  const [customerName, setCustomerName] = useState("");

  const [expenseModel, setExpenseModel] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [search, setSearch] = useState(false);
  const [reCalculate, setReCalculate] = useState(false);

  const [showSearched, setShowSearched] = useState(false);

  const [TakeoutData, setTakeoutData] = useState({});

  const [fromEdit, setFromEdit] = useState(false);
  const [TruefalseCertificate, setTruefalseCertificate] = useState(false);

  useEffect(() => {
    props.clearCustomer();
    props.checkUser();
    props.getSingleDataEntrySuccess(false);
    setSelectedCustomer({});
    setTakeoutData({});
    clearState();
  }, []);
  const searchEntry = () => {
    setTakeoutData({});
    setSelectedCustomer({});
    clearState();
    setSearch(true);
    setShowSearched(true);
    props.getSingleDataEntry(entryNo, props.permission.branchCode);
  };

  const enterSearch = (e) => {
    // setSelectedCustomer({})
    if (e.keyCode === 13) {
      clearState();
      searchEntry();
    }
  };

  useEffect(() => {
    clearState();
    var url = new URL(window.location);
    let entryNoFromParams = url.searchParams.get("entryNo");
    if (entryNoFromParams) {
      setSearch(true);
      setEntryNo(entryNoFromParams);
      setShowSearched(true);
      setFromEdit(true);
      props.getSingleDataEntry(entryNoFromParams, props.permission.branchCode);
    }
  }, []);

  useEffect(() => {
    console.log(props.singleEntry, "props.singleEntry");
    if (props.singleEntry) {
      console.log("dasdasdas");
      setTakeoutData(props.singleEntry);
    } else {
      setTakeoutData({});
    }
  }, [props.singleEntry]);
  useEffect(() => {
    clearState();
    console.log(TakeoutData, "customerUpdate");
    console.log("customerUpdate");
    if (TakeoutData.customerId) {
      console.log("runnnnnnnn");
      props.getCustomer(TakeoutData.customerId, props.permission.branchCode);
    }
  }, [TakeoutData]);

  useEffect(() => {
    console.log("props.singleEntry", props.singleEntry.boxNo);
    if (props.singleEntry && search) {
      // console.log(props.singleEntry, "props.singleEntry");
      if (
        props.singleEntry.consultationArray &&
        props.singleEntry.consultationArray.length
      ) {
        setConsultantBool(true);
      }
      setItems(
        props.singleEntry.consultationArray &&
          props.singleEntry.consultationArray.length
          ? props.singleEntry.consultationArray
          : props.singleEntry.items
      );
      setAddedExpenses(props.singleEntry.expenses);
      setComments(props.singleEntry.customerComments);
      setAdvance(props.singleEntry.advance);
      setDiscountRequest(props.singleEntry.discountAmount);
      setDiscountAmount(props.singleEntry.discountAmount);
      setDiscountReason(props.singleEntry.discountReason);
      setDeliveryDate(props.singleEntry.deliveryDate);
      setBoxOrPacket(props.singleEntry.selectBoxOrPacket);
      setBoxOrPacketNo(props.singleEntry.boxNo);
      setCustomerId(props.singleEntry.customerId);
      setDiscountApproved(props.singleEntry.discountApproved);
      setEntryNo(props.singleEntry.entryNo);
      setShowSearched(true);
      setCustomerName(props.singleEntry.customerName);
      // console.lo
      if (
        props.singleEntry.consultationArray &&
        props.singleEntry.consultationArray.length
      ) {
        let tA = 0;
        for (let i = 0; i < props.singleEntry.consultationArray.length; i++) {
          let {
            sealRate,
            mineRate,
            urgentRate,
            goldTestingRate,
          } = props.singleEntry.consultationArray[i];
          let getServiceRate =
            Number(props.singleEntry.consultationArray[i].serviceRate) +
            Number(sealRate) +
            Number(mineRate) +
            Number(urgentRate) +
            Number(goldTestingRate);

          tA += Number(getServiceRate);
        }
        setTotalAmount(tA);
        console.log(
          Number(tA) - Number(props.singleEntry.advance),
          "adsadasdas"
        );
        setBalance(Number(tA) - Number(props.singleEntry.advance));
        if (props.singleEntry.discountApproved === "Decline") {
          console.log(
            Number(tA) - Number(props.singleEntry.advance),
            "adsadasdas"
          );
          setBalance(Number(tA) - Number(props.singleEntry.advance));
        } else if (props.singleEntry.discountApproved === "Approved") {
          console.log(
            Number(tA) -
              Number(props.singleEntry.advance) -
              Number(discountAmount),
            "adsadasdas"
          );
          setBalance(
            Number(tA) -
              Number(props.singleEntry.advance) -
              Number(discountAmount)
          );
        }
      } else {
        let tA = 0;
        if (props.singleEntry.items) {
          for (let i = 0; i < props.singleEntry.items.length; i++) {
            let {
              sealRate,
              mineRate,
              urgentRate,
              goldTestingRate,
            } = props.singleEntry.items[i];
            let getServiceRate =
              Number(props.singleEntry.items[i].serviceRate) +
              Number(sealRate) +
              Number(mineRate) +
              Number(urgentRate) +
              Number(goldTestingRate);

            tA += Number(getServiceRate);
          }
        }
        setTotalAmount(tA);
        console.log(
          Number(tA) - Number(props.singleEntry.advance),
          "adsadasdas"
        );
        setBalance(Number(tA) - Number(props.singleEntry.advance));
        // setTotalAmount(props.singleEntry.totalAmount)
        if (props.singleEntry.discountApproved === "Decline") {
          console.log(
            Number(tA) - Number(props.singleEntry.advance),
            "adsadasdas"
          );
          setBalance(Number(tA) - Number(props.singleEntry.advance));
        } else if (props.singleEntry.discountApproved === "Approved") {
          console.log(
            Number(tA) -
              Number(props.singleEntry.advance) -
              Number(discountAmount),
            "adsadasdas"
          );
          setBalance(
            Number(tA) -
              Number(props.singleEntry.advance) -
              Number(discountAmount)
          );
        }
      }

      let customerId = props.singleEntry.customerId;

      props.filteredCustomer(customerId);
    } else {
      clearState();
    }
  }, [props.singleEntry, props.customer]);

  useEffect(() => {
    if (props.customer) {
      setSelectedCustomer({});
      setPreBalanceOrAdvance(props.customer.TotalAdvanceorBalance);
      // console.log(props.customer,'customeerFilterrrrrrr')
      setSelectedCustomer(props.customer);
      let membershipDetail = props.customer.membershipDetail;
      props.activeOrNorActive(
        membershipDetail.length
          ? membershipDetail[membershipDetail.length - 1].startDate
          : false,
        membershipDetail.length
          ? membershipDetail[membershipDetail.length - 1].endDate
          : false
      );
    }
  }, [props.customer]);

  const updateSingleItem = (pushToNext, bool, item, itemId) => {
    props.updateSingleItemCopy(
      item,
      bool,
      pushToNext,
      itemId,
      entryNo,
      props.permission.branchCode
    );
  };

  const [updateComp, setUpdateComp] = useState(false);
  const updateItem = (item, bool, pushToNext, itemId) => {
    // setItems(arr);
    setUpdateComp(!updateComp);
    // console.log(' bool se pehle mill gyaaa')
    // if (bool) {
    // console.log('mill gyaaa')
    updateSingleItem(pushToNext, bool, item, itemId);
    // }
  };

  // useEffect(() => {
  // 	console.log(balance, "balance");
  // }, [balance]);

  useEffect(() => {
    let getServiceRate = 0;
    let expenseAmount = 0;
    if (items) {
      // console.log('runnn', items)
      for (let i = 0; i < items.length; i++) {
        let { sealRate, mineRate, urgentRate, goldTestingRate } = items[i];
        getServiceRate +=
          Number(items[i].serviceRate) +
          Number(sealRate) +
          Number(mineRate) +
          Number(urgentRate) +
          Number(goldTestingRate);
        // console.log(getServiceRate, "getServiceRate");
      }
      if (getServiceRate) {
        setTotalAmount(getServiceRate + expenseAmount);
        console.log(getServiceRate - advance, "adsadasdas");
        setBalance(getServiceRate - advance);
      }
    }
    if (addedExpenses) {
      // console.log(addedExpenses);
      for (let i = 0; i < addedExpenses.length; i++) {
        expenseAmount += Number(addedExpenses[i].expenseAmount);
      }
      if (expenseAmount) {
        setTotalAmount(getServiceRate + Number(expenseAmount));
        console.log(
          getServiceRate + Number(expenseAmount) - advance,
          "adsadasdas"
        );
        setBalance(getServiceRate + Number(expenseAmount) - advance);
      }
    }
  }, [items, addedExpenses, updateComp]);

  useEffect(() => {
    if (props.singleEntry && search) {
      // console.log(props.singleEntry);
      if (
        props.singleEntry.consultationArray &&
        props.singleEntry.consultationArray.length
      ) {
        let tA = 0;
        for (let i = 0; i < props.singleEntry.consultationArray.length; i++) {
          let {
            sealRate,
            mineRate,
            urgentRate,
            goldTestingRate,
          } = props.singleEntry.consultationArray[i];
          let getServiceRate =
            Number(props.singleEntry.consultationArray[i].serviceRate) +
            Number(sealRate) +
            Number(mineRate) +
            Number(urgentRate) +
            Number(goldTestingRate);

          tA += Number(getServiceRate);
        }
        tA = tA + addedExpensesAmount;
        setTotalAmount(tA);
        if (props.singleEntry.discountApproved === "Decline") {
          console.log(
            Number(tA) - Number(props.singleEntry.advance),
            "adsadasdas"
          );
          setBalance(Number(tA) - Number(props.singleEntry.advance));
        } else if (props.singleEntry.discountApproved === "Approved") {
          console.log(
            Number(tA) -
              Number(props.singleEntry.advance) -
              Number(discountAmount),
            "adsadasdas"
          );
          setBalance(
            Number(tA) -
              Number(props.singleEntry.advance) -
              Number(discountAmount)
          );
        }
      } else {
        let tA = 0;
        if (props.singleEntry.items) {
          for (let i = 0; i < props.singleEntry.items.length; i++) {
            let {
              sealRate,
              mineRate,
              urgentRate,
              goldTestingRate,
            } = props.singleEntry.items[i];
            let getServiceRate =
              Number(props.singleEntry.items[i].serviceRate) +
              Number(sealRate) +
              Number(mineRate) +
              Number(urgentRate) +
              Number(goldTestingRate);

            tA += Number(getServiceRate);
          }
        }
        tA = tA + addedExpensesAmount;
        setTotalAmount(tA);
        // console.log(paymentRecieved);
        if (props.singleEntry.discountApproved === "Decline") {
          console.log(
            Number(tA) - Number(props.singleEntry.advance),
            "adsadasdas"
          );
          setBalance(Number(tA) - Number(props.singleEntry.advance));
        } else if (props.singleEntry.discountApproved === "Approved") {
          console.log(
            Number(tA) -
              Number(props.singleEntry.advance) -
              Number(discountAmount),
            "adsadasdas"
          );
          setBalance(
            Number(tA) -
              Number(props.singleEntry.advance) -
              Number(discountAmount)
          );
        }
      }
      // setBalance((Number(props.singleEntry.totalAmount) - Number(props.singleEntry.advance)) - paymentRecieved)
    }
  }, [paymentRecieved, addedExpensesAmount, items]);
  // console.log("take out success", props.singleEntry.userName);

  const SaveDeliveryEntry = (toUpdate) => {
    console.log("Im working delivery dat save", props.singleEntry);
    if (toUpdate) {
      console.log("toUpdate");
      let obj = {
        entryNo: entryNo,
        relatedEntryNo: props.singleEntry.entryNo,
        deliveryDateStr: new Date().toISOString(),
        paymentRecieved: Number(paymentRecieved),
        PreAdvanceOrBalance: Number(preBalanceOrAdvance),
        totalAmount: Number(totalAmount),
        deliveryDate: dateFormat(new Date()),
        userName: props.singleEntry.userName,
        customerId: customerId,
        discountApproved:
          props.permission.userType.toLowerCase() == "admin"
            ? "Approved"
            : props.singleEntry.discountApproved,
        discountAmount: discountAmount,
      };

      console.log(obj);
      props.SaveDeliveryDataEntry(
        obj,
        clearState,
        "successfully delivered",
        false,
        false,
        props.permission.branchCode,
        jumpPrintPage
      );
      // entryDate: props.singleEntry.entryDate,
      // totalAmount: totalAmount,
      // deliveryDate: deliveryDate,
      // advance: advance,
      // boxNo: boxOrPacketNo,
      // branchCode: props.singleEntry.branchCode,
      // selectBoxOrPacket: boxOrPacket,
      // checkBoxAskForDiscount: discountRequest ? "true" : "false",
      // customerComments: comments,
      // userName: props.user.username,
      // discountApproved: props.permission.userType.toLowerCase() == "admin" ? "Approved" : "InProcess",
      // discountAmount: discountAmount,
      // discountReason: discountReason,
      // customerId: customerId,
      // customerName: customerName,
      // items: consultantBool ? props.singleEntry.items : items,
      // consultationArray: consultantBool ? items : [],
      // expenses: addedExpenses,
      // deliveryObj: {
      // 	paymentRecieved: Number(paymentRecieved),
      // 	PreAdvanceOrBalance: Number(preBalanceOrAdvance),
      // 	totalAmount: Number(totalAmount),
      // 	deliveryDate: dateFormat(new Date()),
      // 	deliverdeliveryDateStr: new Date(),
      // 	userName: props.singleEntry.userName,
      // 	deliveryDateStr: new Date().toISOString()
    }
  };

  const save = (toUpdate) => {
    console.log("hiiiiiii");
    if (props.singleEntry) {
      console.log(
        "Im chekcing what's in props.single entry",
        props.singleEntry
      );
      if (
        props.singleEntry.discountApproved === "InProcess" &&
        Number(props.singleEntry.discountAmount) > 0
      ) {
        alert("Discount Is Not Decided Yet");
        console.log("if");
      } else {
        // console.log('else')

        let length = props.singleEntry.consultationArray.length
          ? props.singleEntry.consultationArray.length
          : props.singleEntry.items.length
          ? props.singleEntry.items.length
          : 0;
        if (
          props.singleEntry.consultationArray.length &&
          length !=
            props.singleEntry.consultationArray.filter(
              (a, i) =>
                a.reportObj ||
                a.consultationDetailColor ||
                a.service == "No Decision"
            ).length
        ) {
          // console.log(props.singleEntry.consultationArray.length);
          return alert("Report Not Generated");
        } else if (
          props.singleEntry.consultationArray.length
            ? false
            : props.singleEntry.items.length &&
              length !=
                props.singleEntry.items.filter(
                  (a, i) =>
                    a.reportObj ||
                    a.consultationDetailColor ||
                    a.service == "No Decision"
                ).length
        ) {
          return alert("Report Not Generated");
        } else {
          if (toUpdate) {
            console.log("toUpdate");
            let obj = {
              entryDate: props.singleEntry.entryDate,
              totalAmount: totalAmount,
              deliveryDate: deliveryDate,
              advance: advance,
              boxNo: boxOrPacketNo,
              branchCode: props.singleEntry.branchCode,
              selectBoxOrPacket: boxOrPacket,
              checkBoxAskForDiscount: discountRequest ? "true" : "false",
              customerComments: comments,
              userName: props.user.username,
              discountApproved:
                props.permission.userType.toLowerCase() == "admin"
                  ? "Approved"
                  : "InProcess",
              discountAmount: discountAmount,
              discountReason: discountReason,
              customerId: customerId,
              customerName: customerName,
              items: consultantBool ? props.singleEntry.items : items,
              consultationArray: consultantBool ? items : [],
              expenses: addedExpenses,
              deliveryObj: {
                paymentRecieved: Number(paymentRecieved),
                PreAdvanceOrBalance: Number(preBalanceOrAdvance),
                totalAmount: Number(totalAmount),
                deliveryDate: dateFormat(new Date()),
                deliverdeliveryDateStr: new Date(),
                userName: props.singleEntry.userName,
                deliveryDateStr: new Date().toISOString(),
              },
            };
            console.log(obj);
            props.updateDataEntry(
              obj,
              clearState,
              "successfully delivered",
              false,
              false,
              props.permission.branchCode,
              jumpPrintPage
            );
          } else {
            console.log("toUpdate");
            let obj = {
              ...props.singleEntry,
              deliveryObj: {
                paymentRecieved: Number(paymentRecieved),
                PreAdvanceOrBalance: Number(preBalanceOrAdvance),
                totalAmount: Number(totalAmount),
                deliveryDate: dateFormat(new Date()),
                deliverdeliveryDateStr: new Date(),
                userName: props.singleEntry.userName,
                deliveryDateStr: new Date().toISOString(),
              },
            };
            props.updateDataEntry(
              obj,
              clearState,
              "successfully delivered",
              false,
              false,
              props.permission.branchCode,
              jumpPrintPage
            );
          }
        }
      }
    }
  };

  const clearState = () => {
    setItems("");
    setAddedExpenses("");
    // setComments("");
    // setTotalAmount(0);
    setAdvance(0);
    setDiscountRequest("");
    setDiscountAmount("");
    setDiscountReason("");
    setDeliveryDate(new Date());
    setBoxOrPacket("Box");
    setBoxOrPacketNo("");
    setCustomerId("");
    setDiscountApproved("");
    setBalance(0);
    setPaymentRecieved(0);
    // setEntryNo("");
    setPreBalanceOrAdvance(0);
    props.clearSingleDataEntry();
    props.clearCustomer();
  };

  const editItem = (obj, index) => {
    console.log("objjjjjjjj", obj);
    setEditObj(obj);
    setModal3VisibleFn(true);
    setIndex(index);
  };

  const closeAddItemModel = () => {
    setEditObj("");
    setModal3VisibleFn(false);
  };

  const deleteItem = (arr, data) => {
    // setItems(arr);
    // console.log(data);
    // props.deleteDataItem(data, props.permission.branchCode, items, setItems);
    setItems(arr);
    if (!arr.length) {
      setTotalAmount(0);
      setBalance(0 - advance);
    }
  };

  useEffect(() => {
    if (props.singleEntry.discountApproved === "Decline") {
      console.log(Number(totalAmount) - Number(advance), "adsadasdas");
      setBalance(Number(totalAmount) - Number(advance));
    } else if (props.singleEntry.discountApproved === "Approved") {
      console.log(
        Number(totalAmount) - Number(advance) - Number(discountAmount),
        "adsadasdas"
      );
      setBalance(
        Number(totalAmount) - Number(advance) - Number(discountAmount)
      );
    }
    // setBalance(totalAmount - advance - discountAmount)
  }, [advance, discountAmount]);

  const update = (pushToNext, bool, itemId) => {
    // console.log('updateFunRun')
    var url = new URL(window.location);
    let entryNoFromParams = url.searchParams.get("entryNo")
      ? url.searchParams.get("entryNo")
      : props.singleEntry.entryNo;

    console.log("itemId", props.singleEntry.id);
    let { sealRate, mineRate, urgentRate, goldTestingRate } = items[0];
    let obj = {
      entryNo: entryNoFromParams,
      branchCode: props.singleEntry.branchCode,
      id: props.singleEntry.id,
      entryDate: props.singleEntry.entryDate,
      customerName: customerName,
      date: new Date().toString(),
      totalAmount: totalAmount,
      deliveryDate: deliveryDate,
      advance: advance,
      boxNo: boxOrPacketNo,
      selectBoxOrPacket: boxOrPacket,
      checkBoxAskForDiscount: discountRequest ? "true" : "false",
      customerComments: comments,
      userName: props.user.username,
      discountApproved:
        props.permission.userType.toLowerCase() == "admin"
          ? "Approved"
          : "InProcess",
      discountAmount: discountAmount,
      discountReason: discountReason,
      customerId: customerId,
    };

    props.updateDataEntry(
      obj,
      clearState,
      "successfully delivered",
      false,
      false,
      props.permission.branchCode,
      // jumpPrintPage
      () => {}
    );

    // props.updateSingleItemCopy(
    // 	obj,
    // 	clearState,
    // 	"Successfully updated",
    // 	goBack,
    // 	"",
    // 	props.singleEntry.branchCode,
    // 	"",
    // 	entryNoFromParams,
    // 	pushToNext,
    // 	bool,
    // 	itemId
    // );
  };

  const goBack = () => {
    props.history.goBack();
  };

  useEffect(() => {
    if (addedExpenses && addedExpenses.length) {
      let t = 0;
      for (let i = 0; i < addedExpenses.length; i++) {
        t += Number(addedExpenses[i].expenseAmount);
      }
      setAddedExpensesAmount(t);
    } else {
      setAddedExpensesAmount(0);
    }
  }, [addedExpenses]);

  const dateFormatt = "YYYY/MM/DD";
  const today = new Date();
  let delDate = `${today.getFullYear()}/${
    today.getMonth().toString().length + 1 > 1
      ? today.getMonth() + 1
      : "0" + (today.getMonth() + 1)
  }/${
    today.getDate().toString().length > 1
      ? today.getDate()
      : "0" + today.getDate()
  }`;

  const [cantDeliver, setCantDeliver] = useState(true);
  const [stopEffect, setStopEffect] = useState(true);
  useEffect(() => {
    if (props.singleEntry) {
      setStopEffect(false);
      if (stopEffect) {
        // console.log(props.singleEntry);
        let data = props.singleEntry;
        let bool = false;
        if (data) {
          if (
            data.discountApproved === "InProcess" &&
            data.discountAmount > 0
          ) {
            setCantDeliver(true);
          }
        }
      }
    }
  }, [props.singleEntry]);
  const [discountLock, setDiscountLock] = useState(false);
  useEffect(() => {
    if (props.userPermissions.userType.toLowerCase() !== "user") {
      if (fromEdit) {
        setDiscountLock(false);
      } else {
        if (props.userPermissions.userType.toLowerCase() === "admin") {
          setDiscountLock(false);
        } else {
          setDiscountLock(true);
        }
      }
    } else {
      setDiscountLock(true);
    }
  }, [fromEdit, props.userPermissions]);

  // useState(() => {
  // 	console.log(cantDeliver);
  // 	console.log("cantDeliver");
  // }, [cantDeliver]);

  return (
    <>
      <div className="takeInItem">
        <Row className="spaceBetween">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="selectCustomer">
              <Input
                disabled={fromEdit}
                className="select"
                size="large"
                showSearch
                placeholder="Entry No."
                onChange={(e) => setEntryNo(e.target.value)}
                value={entryNo}
                onKeyUp={(e) => enterSearch(e)}
                autoFocus
                suffix={
                  fromEdit ? (
                    <>
                      <Button
                        type="primary"
                        onClick={() => props.history.goBack()}
                        style={{
                          background: "#001529",
                          borderColor: "#001529",
                          borderRadius: "50px",
                        }}
                      >
                        Back
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        type="primary"
                        onClick={searchEntry}
                        style={{
                          background: "#001529",
                          borderColor: "#001529",
                          marginRight: 5,
                        }}
                      >
                        Search
                      </Button>
                      <Button
                        onClick={() => clearState()}
                        type="primary"
                        style={{
                          background: "#f50057",
                          borderColor: "#f50057",
                          borderRadius: "50px",
                        }}
                      >
                        Clear
                      </Button>
                    </>
                  )
                }
              />
            </div>
          </Col>
          {showSearched ? (
            !props.singleEntry && search ? (
              <h3 className="alreadyDelivered">
                No entry found related to this entry number.
              </h3>
            ) : (
              <div className="addItemSec">
                {props.singleEntry && props.singleEntry.deliveryObj && search && (
                  <h3 className="alreadyDelivered" style={{ marginTop: "0px" }}>
                    Already Delivered,
                    <br />
                    {props.userPermissions &&
                    props.userPermissions.userType == "Admin" ? (
                      <Popconfirm
                        onConfirm={() => {
                          props.deleteDelivery(
                            props.singleEntry.deliveryObj[0].id
                          );
                          console.log("chekcing id", props.singleEntry);
                        }}
                        title="Are you sure you want to delete this Delivery?"
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          type="primary"
                          style={{
                            background: "#f50057",
                            borderColor: "#f50057",
                          }}
                        >
                          Delete Delivery
                        </Button>
                      </Popconfirm>
                    ) : null}
                  </h3>
                )}
                <div className="spaceBetween customerPortion">
                  <CustomerDetails
                    modal1Visible={modal1Visible}
                    setModal1Visible={setModal1Visible}
                    setModal1VisibleFn={setModal1VisibleFn}
                    selectedCustomer={selectedCustomer}
                    // takeOut={true}
                  />
                  {/* <h3 className="addItemHead" onClick={() => console.log(props.singleEntry)}>
										All Entries
									</h3> */}
                  <EntrieModal
                    modal2Visible={modal2Visible}
                    // modal2Visible={modal2Visible}
                    setModal2Visible={setModal2Visible}
                    setModal2VisibleFn={setModal2VisibleFn}
                    selectedCustomer={selectedCustomer}
                  />
                </div>
                <Row className="spaceBetween notAligned">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="formMainDiv">
                      <div className="formDiv">
                        <div className="innerForm">
                          <div className="addEnteryDiv">
                            {/* {editDelete || (userPermissions && userPermissions.userType == "Admin") ? (

														<AddItems modal3Visible={modal3Visible} setModal3Visibl={setModal3Visible} setModal3VisibleFn={setModal3VisibleFn} selectedCustomer={selectedCustomer} addData={addData} />
													) : null} */}
                          </div>
                          <div className="inputFields w100P">
                            <h5>Comments</h5>
                            <div>
                              <Input
                                type="text"
                                value={comments}
                                onChange={(ev) => setComments(ev.target.value)}
                              />
                            </div>
                          </div>

                          <div className="inputFields">
                            <label>
                              <h5>Total Amount</h5>
                              <InputNumber
                                value={totalAmount ? totalAmount : ""}
                                disabled
                              />
                            </label>
                          </div>
                          <div className="inputFields">
                            <label>
                              <h5>Advance</h5>
                              <InputNumber
                                onChange={(e) => setAdvance(e)}
                                value={advance ? advance : ""}
                                disabled={!fromEdit}
                              />
                            </label>
                          </div>
                          <div className="inputFields">
                            <label>
                              <h5>Pre Balance Or Advance</h5>
                              <InputNumber
                                value={
                                  preBalanceOrAdvance ? preBalanceOrAdvance : ""
                                }
                                disabled
                              />
                            </label>
                          </div>
                          <div className="inputFields ">
                            <label>
                              <h5>Balance</h5>
                              {/* <InputNumber className={
																(((totalAmount - advance) + preBalanceOrAdvance - (props.singleEntry.discountApproved.toLowerCase() === 'approved' ? discountAmount : 0)) - paymentRecieved) > 0 ?
																	"highlightRed" : ""} value={(((totalAmount - advance) + preBalanceOrAdvance - (props.singleEntry.discountApproved.toLowerCase() === 'approved' ? discountAmount : 0)) - paymentRecieved)} disabled /> */}
                              <InputNumber
                                className={
                                  totalAmount -
                                    advance +
                                    preBalanceOrAdvance -
                                    (props.singleEntry.discountApproved.toLowerCase() ===
                                    "approved"
                                      ? discountAmount
                                      : 0) -
                                    paymentRecieved >
                                  0
                                    ? "highlightRed"
                                    : ""
                                }
                                value={
                                  totalAmount -
                                  advance +
                                  (preBalanceOrAdvance > 0
                                    ? preBalanceOrAdvance - advance
                                    : preBalanceOrAdvance + advance) -
                                  (props.singleEntry.discountApproved.toLowerCase() ===
                                  "approved"
                                    ? discountAmount
                                    : 0) -
                                  paymentRecieved
                                }
                                disabled
                              />
                            </label>
                          </div>
                          {!fromEdit && (
                            <>
                              <div className="inputFields">
                                <label>
                                  <h5>Payemnt Recieved</h5>
                                  <InputNumber
                                    value={
                                      paymentRecieved ? paymentRecieved : ""
                                    }
                                    onChange={(e) => setPaymentRecieved(e)}
                                    // disabled={
                                    // 	props.singleEntry && props.singleEntry.deliveryObj && search
                                    // }
                                  />
                                </label>
                              </div>
                            </>
                          )}
                          {props.singleEntry.discountAmount > 0 ? (
                            <div
                              className="inputFields "
                              style={{ fontSize: "12px" }}
                            >
                              <b>
                                Discount Status:{" "}
                                {props.singleEntry.discountApproved}
                              </b>
                            </div>
                          ) : null}
                          <div className="inputFields ">
                            <label className="w100P">
                              <h5>Expenses</h5>
                              <AddAndViewExpense
                                type={"takeOut"}
                                entryNo={props.singleEntry.entryNo}
                                expenseModel={expenseModel}
                                setExpenseModel={setExpenseModel}
                                selectedCustomer={selectedCustomer}
                                addedExpenses={addedExpenses}
                                setAddedExpenses={setAddedExpenses}
                                fromEdit={fromEdit}
                              />
                            </label>
                          </div>
                          {!fromEdit && <div className="inputFields "></div>}
                          <div className="inputFields "></div>
                          <div className="inputFields">
                            <label>
                              <h5>Discount</h5>
                              <Checkbox
                                checked={discountRequest}
                                value={discountRequest ? discountRequest : ""}
                                onChange={(ev) =>
                                  setDiscountRequest(ev.target.checked)
                                }
                                disabled={discountLock}
                              >
                                Discount
                              </Checkbox>
                            </label>
                          </div>

                          {discountRequest ? (
                            <>
                              <div className="inputFields">
                                <label>
                                  <h5>Discount Expected</h5>
                                  <InputNumber
                                    value={discountAmount ? discountAmount : ""}
                                    onChange={(ev) => setDiscountAmount(ev)}
                                    disabled={discountLock}
                                  />
                                </label>
                              </div>
                              <div className="inputFields">
                                <label>
                                  <h5>Reason For Discount</h5>
                                  <Input
                                    value={discountReason ? discountReason : ""}
                                    onChange={(ev) =>
                                      setDiscountReason(ev.target.value)
                                    }
                                    disabled={discountLock}
                                  />
                                </label>
                              </div>
                            </>
                          ) : null}
                          <div className="inputFields">
                            <label>
                              <h5>Delivery Date</h5>
                              <Space direction="vertical">
                                <DatePicker
                                  defaultValue={moment(delDate, dateFormatt)}
                                  disabled={!fromEdit}
                                />
                              </Space>
                            </label>
                          </div>
                          <div className="inputFields">
                            <label>
                              <h5>Box / Packet</h5>
                              <Select
                                value={boxOrPacket ? boxOrPacket : ""}
                                className="select"
                                defaultValue="Box"
                                disabled={!fromEdit}
                              >
                                <Option value="Box">Box</Option>
                                <Option value="Packet">Packet</Option>
                              </Select>
                            </label>
                          </div>
                          <div className="inputFields">
                            <label>
                              <h5>Box</h5>
                              <InputNumber
                                value={boxOrPacketNo ? boxOrPacketNo : ""}
                                disabled={!fromEdit}
                              />
                            </label>
                          </div>
                          <div className="inputFields saveButton w100P">
                            {props.singleEntry &&
                            props.singleEntry.deliveryObj &&
                            search ? (
                              <Button
                                onClick={() => jumpPrintPage(entryNo)}
                                className="w100P highlight"
                              >
                                <SaveOutlined />
                                Check Slip
                              </Button>
                            ) : fromEdit ? (
                              <Button
                                onClick={update}
                                className="w100P highlight"
                              >
                                <SaveOutlined />
                                Update
                              </Button>
                            ) : totalAmount -
                                advance +
                                (preBalanceOrAdvance > 0
                                  ? preBalanceOrAdvance - advance
                                  : preBalanceOrAdvance + advance) -
                                discountAmount -
                                paymentRecieved >
                                0 &&
                              // ) : (((totalAmount - advance) + (preBalanceOrAdvance > 0 ? (preBalanceOrAdvance - advance) : (preBalanceOrAdvance + advance)) - discountAmount) - paymentRecieved) &&
                              props.permission.userType === "Admin" ? (
                              <Popconfirm
                                onConfirm={() => SaveDeliveryEntry(true)}
                                // onConfirm={() => console.log('save')}
                                title="There is some balance, do you want too continue or cancel"
                                okText="Continue"
                                cancelText="Cancel"
                              >
                                <Button
                                  disabled={
                                    preBalanceOrAdvance +
                                      totalAmount -
                                      advance ===
                                      paymentRecieved &&
                                    props.permission.userType === "User"
                                  }
                                  // disabled={
                                  // 	(preBalanceOrAdvance > 0 ? (preBalanceOrAdvance - advance) : (preBalanceOrAdvance + advance)) + totalAmount - advance === paymentRecieved &&
                                  // 	props.permission.userType === "User"
                                  // }
                                  className="w100P highlight"
                                >
                                  <SaveOutlined />
                                  Save
                                </Button>
                              </Popconfirm>
                            ) : (
                              <Button
                                // disabled={cantDeliver}
                                // onClick={() => {
                                // 	if ((((totalAmount - advance) + preBalanceOrAdvance - discountAmount) - paymentRecieved) > 0) {
                                // 		alert("You cant deliver with balance")
                                // 	} else {
                                // 		if (props.permission.userType === "Admin"){
                                // 			save(true)
                                // 		}else{
                                // 			save()
                                // 		}
                                // 	}
                                // }}

                                onClick={() => {
                                  if (
                                    totalAmount -
                                      advance +
                                      (preBalanceOrAdvance > 0
                                        ? preBalanceOrAdvance - advance
                                        : preBalanceOrAdvance + advance) -
                                      discountAmount -
                                      paymentRecieved >
                                    0
                                  ) {
                                    console.log(
                                      props.permission.userType,
                                      "props.permission.userType"
                                    );
                                    alert("You cant deliver with balance");
                                  } else {
                                    if (props.permission.userType === "Admin") {
                                      SaveDeliveryEntry(true);
                                    } else {
                                      SaveDeliveryEntry(true);
                                    }
                                  }
                                }}
                                className="w100P highlight"
                              >
                                <SaveOutlined />
                                Save
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="itemDiv">
                      <Items
                        data={items}
                        editDelete={fromEdit}
                        editItem={editItem}
                        deleteItem={deleteItem}
                        TruefalseCertificate={TruefalseCertificate}
                      />
                      {!selectedCustomer.customerName ? (
                        <div className="spinnerItemDiv">
                          <Spin />
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
            )
          ) : null}

          <AddItems
            fromEdit={fromEdit}
            fromTakeOut={true}
            modal3Visible={modal3Visible}
            setModal3Visibl={setModal3Visible}
            setModal3VisibleFn={setModal3VisibleFn}
            addData={addData}
            items={items}
            selectedCustomer={selectedCustomer}
            editObj={editObj}
            closeAddItemModel={closeAddItemModel}
            index={index}
            updateItem={updateItem}
            consultantBool={consultantBool}
            entry={props.singleEntry}
            editItemBool={true}
            reCalculate={reCalculate}
            setReCalculate={setReCalculate}
            // manager={manager}
          />
        </Row>
      </div>
      {props.dataEntryLoading ? <Loader /> : null}
    </>
  );
}

const mapStateToProps = (store) => ({
  customers: store.customers.customers,
  singleEntry: store.dataEntry.singleEntry,
  filteredCustomerArr: store.customers.filteredCustomer,
  customer: store.customers.customer,
  dataEntryLoading: store.dataEntry.dataEntryLoading,
  permission: store.users.permission,
  user: store.authentication.user,
  userPermissions: store.authentication.userPermissions,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSingleDataEntry,
      filteredCustomer,
      getCustomer,
      updateDataEntry,
      clearSingleDataEntry,
      activeOrNorActive,
      clearCustomer,
      checkUser,
      deleteDataItem,
      deleteDelivery,
      getSingleDataEntrySuccess,
      SaveDeliveryDataEntry,
      updateSingleItemCopy,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TakeOut);
