import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Space, Pagination, Popconfirm } from "antd";
import { Col, Row } from "antd";
import { Table } from "antd";
import {
	deleteGeneratedEntry,
	getRecieptGeneratedEntries,
	getSelectedReceiptNo

} from "../../../../modules/actions/reportsAction";
import { withRouter } from "react-router";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyFilled, PrinterFilled } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { ModalReport } from "./ModalReport";
import { dateFormat } from "../../../../common/common";
import logo from "./../../../../Images/Logo 2.png";
import { getSingleDataEntrySuccess } from "../../../../modules/actions/dataEntryActions";

const { Column } = Table;

function RecieptReports(props) {
	const [modalType, setModalType] = useState('')
	const [page, setPage] = useState(1);
	const [data, setData] = useState({});
	const [pageSize, setPageSize] = useState(10);

	const [isModalVisible, setIsModalVisible] = useState(false);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	
	// useEffect(()=>{

		
	// 	console.log("search input before changed", props.searchInput)
	
	// },[props.keyword, props.searchedEntries])

	useEffect(()=>{
		console.log("search input after changed", props.searchInput)
		console.log("searchedEntries", props.searchedEntries)

	},[props.keyword, props.searchedEntries])

	const confirmDeleteItem = (obj) => {
		let startDate = dateFormat(new Date(props.startDate));
		let endDate = dateFormat(new Date(props.endDate));
		console.log(obj,'asdadasda')
		props.deleteGeneratedEntry(
			obj.id,
			page,
			false,
			props.selectedBranch,
			props.selectedReports,
			startDate,
			endDate,
			props.keyword,
			pageSize
		);
	};

	useEffect(() => {
		console.log(props.startDate, "props.startDate");
		if (props.selectedBranch) {
			let startDate = dateFormat(new Date(props.startDate));
			let endDate = dateFormat(new Date(props.endDate));
			console.log(props.selectedReports,'dsadasdasad')
			if(props.selectedReports.toLowerCase()=="receipt_no" && props.keyword){
				props.getSelectedReceiptNo(
					props.keyword
				);
			
				// props.getRecieptGeneratedEntries(
				// 	page,
				// 	props.selectedReports,
				// 	"",
				// 	"",
				// 	props.keyword,
				// 	props.selectedBranch,
				// 	pageSize
				// );
			}else{
				props.getRecieptGeneratedEntries(
					page,
					props.selectedReports,
					startDate,
					endDate,
					props.keyword,
					props.selectedBranch,
					pageSize,
					props.userType
				);
			}
		
		}
	}, [
		props.selectedReports,
		props.searchBool,
		props.userType,
		// props.startDate,
		// props.endDate,
		page,
		// props.searchEnter,
		// props.permission.branchCode,
		pageSize,
		// props.selectedBranch
	]);
	const [entriesData, setEntriesData] = useState([]);
	
	useEffect(() => {
		console.log(props.startDate, "helooooooooooooooooooooooooooooooo");
		if (props.selectedBranch) {
			let startDate = dateFormat(new Date(props.startDate));
			let endDate = dateFormat(new Date(props.endDate));
		
				props.getSelectedReceiptNo(
					page,
					props.selectedReports,
					startDate,
					endDate,
					props.keyword,
					props.selectedBranch,
					pageSize,
					props.searchEnter,
				);
			
		
		}
	}, [
		props.selectedReports,
		props.searchBool,
		props.searchEnter,
		page,
		pageSize,
	]);
	



	useEffect(() => {
		setEntriesData([])
		console.log(props.entries)
		setEntriesData(props.entries)
	}, [props.entries, pageSize])

	useEffect(() => {
		setData({})
		console.log(props.SingleEntry)
		setData(props.SingleEntry)
	}, [props.SingleEntry, pageSize])



	const certificateNumbers = (record) => {
		console.log("Cerficate Numbers", record);
		let totalCertificates = record.consultationArray && record.consultationArray.length ? record.consultationArray.length : record.items ? record.items.length : 0
		let arr = typeof (record.consultationArray) === 'object' && record.consultationArray.length ? record.consultationArray : record.items
		let generatedNumber = 0
		if (typeof arr === 'object' && arr.length) {

			arr.map((a, i) => {
				if (a.reportObj && a.reportObj.length>0 ||
					a.consultationDetailColor ||
					a.service == "No Decision") {
					generatedNumber += 1
				}
			})
			return `${generatedNumber} / ${totalCertificates}`
		}
	}

	return (
		<div className="recieptReports">
			<Row>
				{/* <button onClick={()=>confirmDeleteItem()}>delete</button> */}
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<div className="tableDiv">
						{props.reportsLoading ? (
							<div className="loaderDiv tableLoader">
								<div>
									<img src={logo} />
								</div>
							</div>
						) : null} 
						<Table pagination={false} className="itemsTable" dataSource={entriesData}>
							<Column
								title="Date"
								key="entryDate"
								render={(text, record) =>
									record.entryDate && (
										<div
											onClick={() => {
												props.getSelectedReceiptNo(record.entryNo);
												// showModal()
												console.log(record , "record aya");
											}}
										>
											{new Date(record.entryDate).getDate().toString().length > 1 ? new Date(record.entryDate).getDate().toString() + '-' : '0' + new Date(record.entryDate).getDate().toString() + '-'}
											{(new Date(record.entryDate).getMonth() + 1).toString().length > 1 ? (new Date(record.entryDate).getMonth() + 1).toString() + '-' : '0' + (new Date(record.entryDate).getMonth() + 1).toString() + '-'}
											{new Date(record.entryDate).getFullYear()}
										
										</div>
									)
								}
							/>
							<Column
								title="Entry No."
								dataIndex="entryNo"
								key="entryNo"
								render={(text, record) => {
									return (
										<div
											onClick={() => {
												props.getSelectedReceiptNo(record.entryNo);
												showModal();
												setModalType('delivery')
											}}
										>
											{text}
										</div>
									);
								}}
							/>
							<Column
								title="Customer ID"
								dataIndex="customerId"
								key="customerId"
								render={(text, record) => {
									return (
										<div
											onClick={() => {
												props.getSelectedReceiptNo(record.entryNo);
												showModal();
												setModalType('delivery')
											}}
										>
											{text}
										</div>
									);
								}}
							/>
							<Column
								title="Customer Name"
								dataIndex="customerName"
								key="customerName"
								render={(text, record) => {
									return (
										<div
											onClick={() => {
												props.getSelectedReceiptNo(record.entryNo);
												showModal();
												setModalType('delivery')
											}}
										>
											{text}
										</div>
									);
								}}
							/>
							<Column
								title="Certificate Generated"
								render={(text, record) => {
									return (
										<div>
											{certificateNumbers(record)}
										</div>
									);
								}}
							/>
							<Column
								title="B/P Number"
								render={(text, record) => {
									return (
										<div>
											{record.selectBoxOrPacket}-<b>{record.boxNo}</b>
										</div>
									);
								}}
							/>
							<Column
								title="Delivered"
								key="address"
								render={(text, record) => (
									<div
										onClick={() => {
											// setData(record);
											props.getSelectedReceiptNo(record.entryNo);
											showModal();
											setModalType('delivery')
										}}
									>
										<Space size="middle">
											{record.deliveryObj ? (
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="16"
													height="16"
													fill="green"
													className="bi bi-check-lg"
													viewBox="0 0 16 16"
												>
													<path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z" />
												</svg>
											) : (
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="16"
													height="16"
													fill="red"
													className="bi bi-x-lg"
													viewBox="0 0 16 16"
												>
													<path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
												</svg>
											)}
										</Space>
									</div>
								)}
							/>
							<Column
								title="Action"
								key="action"
								render={(text, record) => (
									<Space size="middle">
										{props.userPermissions.reciept_generated_edit && (
											<a
												onClick={() => {
													// window.location.href = `/take-out-items?entryNo=${record.entryNo}`
													props.getSingleDataEntrySuccess(false)
													props.history.push(`/take-out-items?entryNo=${record.entryNo}`)
												}}
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="16"
													height="16"
													fill="#001529"
													className="bi bi-pencil-fill"
													viewBox="0 0 16 16"
												>
													<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
												</svg>
											</a>
										)}

										{props.userPermissions.reciept_generated_delete && (
											<Popconfirm
												onConfirm={() => confirmDeleteItem(record)}
												title="Are you sure you want to delete this item?"
												okText="Yes"
												cancelText="No"
											>
												<a>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														fill="rgb(245, 0, 87)"
														className="bi bi-trash-fill"
														viewBox="0 0 16 16"
													>
														<path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
													</svg>
												</a>
											</Popconfirm>
										)}

										<CopyToClipboard text={record.entryNo}>
											<CopyFilled style={{ fill: "#1890ff", color: "#1890ff" }} fill="#1890ff" />
										</CopyToClipboard>
										<a href={`/take-out-Print/${record.entryNo}`}>
											<PrinterFilled />
										</a>
									</Space>
								)}
							/>
						</Table>
					</div>
				</Col>
			</Row>
			<Pagination
				onChange={(ev, size) => {
					setPage(ev);
					setPageSize(size);
				}}
				pageSize={pageSize}
				current={props.page}
				total={props.total}
			/>

			<ModalReport
				isModalVisible={isModalVisible}
				setIsModalVisible={setIsModalVisible}
				showModal={showModal}
				handleOk={handleOk}
				handleCancel={handleCancel}
				data={data}
				modalType={modalType}
				entries={
					data.date ? (data&&data.consultationArray && data.consultationArray.length>0 ? data.consultationArray : data.items) : []
				}
			/>
		</div>
	);
}

const mapStateToProps = (store) => {
	return {
		entries: store.reports.entries ? store.reports.entries : [],
		SingleEntry: store.reports.SingleEntry ? store.reports.SingleEntry : {},
		page: store.reports.page,
		searchInput: store.reports.searchInput,
		total: store.reports.total,
		reportsLoading: store.reports.reportsLoading,
		userPermissions: store.authentication.userPermissions,
		permission: store.users.permission,
		userType: store.users.userType,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getRecieptGeneratedEntries,
			deleteGeneratedEntry,
			getSingleDataEntrySuccess,
			getSelectedReceiptNo
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RecieptReports));
