import { message } from "antd";
import Axios from "axios";
import { getCookie } from "../../common/common";
import store from "../store";

// Reports loader true
function ReportsLoadingBool(bool) {
  return (dispatch) => {
    dispatch(ReportsLoading(bool));
  };
}
export const REPORTS_LOADING = "REPORTS_LOADING";
export const ReportsLoading = (bool) => {
  console.log(bool, "bool");
  return {
    type: REPORTS_LOADING,
    payload: bool,
  };
};

//get selected reciept no

export function getSelectedReceiptNo(

  keyword
) {

  return (dispatch) => {
    console.log("search enter", keyword)
    dispatch(ReportsLoadingBool(true));
    let token = getCookie("authToken");
    Axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}dataEntry/${keyword}?`,
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then((res) => {
        console.log("resssss recipt no", res.data);
        dispatch(
          getSelectedRecieptNoSuccess(
            res.data,
          
          )
        );
        dispatch(ReportsLoadingBool(false));
      })
      .catch((res) => {
        dispatch(ReportsLoadingBool(false));
      });
  };
}

export const GET_SELECTED_RECIEPT_NO = "GET_SELECTED_RECIEPT_NO";

export const getSelectedRecieptNoSuccess = (data) => {
  console.log(data, "bool succesess");
  return {
    type: GET_SELECTED_RECIEPT_NO,
    payload: data,
  };
};




//Get Reciept generated entries
export function getDirectReceivedEntries(
  page,
  value,
  startDate,
  endDate,
  keyword,
  branchCode,
  pageSize
) {
  return (dispatch) => {
    dispatch(ReportsLoadingBool(true));
    let token = getCookie("authToken");
    Axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}getDataEntry/?page=${page ? page : 1
        }&report_type=${value}${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ""}&searchText=${keyword}&branchCode=${branchCode}&page_size=${pageSize}`,
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then((res) => {
        console.log("resssss", res.data);
        dispatch(
          getCashRecieptGeneratedEntriesSuccess(
            res.data.results,
            res.data.page,
            res.data.total,
            res.data.summaryObj
          )
        );
        dispatch(ReportsLoadingBool(false));
      })
      .catch((res) => {
        dispatch(ReportsLoadingBool(false));
      });
  };
}
export const GET_CASH_RECIEPTS_GENERATED_ENTRIES = "GET_CASH_RECIEPTS_GENERATED_ENTRIES";
export const getCashRecieptGeneratedEntriesSuccess = (
  data,
  page,
  total,
  footer
) => ({
  type: GET_CASH_RECIEPTS_GENERATED_ENTRIES,
  payload: {
    data,
    page,
    total,
    footer,
  },
});




//Get Reciept generated entries
export function getRecieptGeneratedEntries(
  page,
  value,
  startDate,
  endDate,
  keyword,
  branchCode,
  pageSize,
  userType,
) {
  return (dispatch) => {
    dispatch(ReportsLoadingBool(true));
    let token = getCookie("authToken");
    Axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}dataEntry/?page=${page ? page : 1
        }&report_type=${value}${startDate ? `&start_date=${startDate}` : ''}${endDate ? `&end_date=${endDate}` : ""}&searchText=${keyword}&branchCode=${branchCode}&page_size=${pageSize}${userType ? `&userType=${userType}` : ''}`,
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then((res) => {
        console.log("resssss", res.data);
        dispatch(
          getRecieptGeneratedEntriesSuccess(
            res.data.results,
            res.data.page,
            res.data.total,
            res.data.summaryObj
          )
        );
        dispatch(ReportsLoadingBool(false));
      })
      .catch((res) => {
        dispatch(ReportsLoadingBool(false));
      });
  };
}
export const GET_RECIEPTS_GENERATED_ENTRIES = "GET_RECIEPTS_GENERATED_ENTRIES";
export const getRecieptGeneratedEntriesSuccess = (
  data,
  page,
  total,
  footer
) => ({
  type: GET_RECIEPTS_GENERATED_ENTRIES,
  payload: {
    data,
    page,
    total,
    footer,
  },
});

//Delete Reciept generated entries
export function deleteGeneratedEntry(
  id,
  page,
  service,
  branchCode,
  value,
  startDate,
  endDate,
  keyword,
  pageSize
) {
  return (dispatch) => {
    dispatch(ReportsLoadingBool(true));
    let token = getCookie("authToken");
    Axios({
      method: "delete",
      url: `${process.env.REACT_APP_BACKEND_URL}dataEntry/${id}/?branchCode=${branchCode}`,
      headers: {
        Authorization: `JWT ${token}`
      }
    })
      .then((res) => {
        message.success("Entry Deleted");
        if (service) {
          dispatch(getServicesEntries(page, service, startDate, endDate, keyword, branchCode, pageSize));
        } else {
          dispatch(
            getRecieptGeneratedEntries(page, value, startDate, endDate, keyword, branchCode, pageSize)
          );
        }
        // dispatch(ReportsLoadingBool(false))
      })
      .catch((res) => {
        dispatch(ReportsLoadingBool(false));
      });
  };
}

// update direct cash recieve
export function editDirectCash(
  obj,
  branchCode,
  setCashRecivedData,
  page,
  pageSize
) {
  return (dispatch) => {
    let token = getCookie("authToken");
    dispatch(ReportsLoadingBool(true));

    Axios({
      method: "put",
      url: `${process.env.REACT_APP_BACKEND_URL}cashRcEntry/${obj.id}/?branchCode=${branchCode}`,
      headers: {
        Authorization: `JWT ${token}`,
      },
      data: obj,
    })
      .then((res) => {
        // dispatch(saveDirectCashSuccess(res.data));
        // dispatch(dataEntryLoading(false))
        // setCashRecivedData(obj)
        // dispatch(getCashReceivedSingleItem(res.data))
        dispatch(getDirectCash(page, branchCode, pageSize));
        message.success("Successfully Update");
      })
      .catch((res) => {
        dispatch(ReportsLoadingBool(false));
      });
  };
}

// delete direct cash recieve
export function deleteDirectCash(obj, branchCode, page, pageSize) {
  return (dispatch) => {
    let token = getCookie("authToken");
    dispatch(ReportsLoadingBool(true));

    Axios({
      method: "delete",
      url: `${process.env.REACT_APP_BACKEND_URL}cashRcEntry/${obj.id}/?branchCode=${branchCode}`,
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then((res) => {
        // dispatch(saveDirectCashSuccess(res.data));
        // dispatch(dataEntryLoading(false))
        // setCashRecivedData(obj)
        // dispatch(getCashReceivedSingleItem(res.data))
        dispatch(getDirectCash(page, branchCode, pageSize));
        message.success("Successfully Delete");
      })
      .catch((res) => {
        dispatch(ReportsLoadingBool(false));
      });
  };
}

// get direct cash recieve
export function getDirectCash(startDate, endDate, page, searchEnter, branchCode, pageSize) {
  return (dispatch) => {
    dispatch(ReportsLoadingBool(true));
    let token = getCookie("authToken");
    Axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}cashRcEntry/?page=${page ? page : 1
        }&report_type=Direct_Cash&startDate=${startDate}&endDate=${endDate}&searchText=${searchEnter}&branchCode=${branchCode}&page_size=${pageSize}`,
      headers: {
        Authorization: `JWT ${token}`
      }
    })
      .then((res) => {
        console.log("want to see what's", res.data.results);
        dispatch(getDirectCashSuccess(res.data.results, res.data.page, res.data.total, res.data.footer));
        dispatch(ReportsLoadingBool(false));
        // message.success("Successfully added")
      })
      .catch((res) => {
        dispatch(ReportsLoadingBool(false));
      });
  };
}
export const GET_DIRECT_CASH = "GET_DIRECT_CASH";
export const getDirectCashSuccess = (data, page, total, footer) => ({
  type: GET_DIRECT_CASH,
  payload: {
    data,
    page,
    total,
    footer
  }
});

//Get Customer summary entries
export function getCustomerSummaryEntry(
  page,
  value,
  startDate,
  endDate,
  keyword,
  branchCode,
  pageSize,
  order
) {
  console.log("OK");
  return (dispatch) => {
    dispatch(ReportsLoadingBool(true));
    let token = getCookie("authToken");
    Axios({
      method: "get",
      // url: `${process.env.REACT_APP_BACKEND_URL}getDataEntry/?page=${page ? page : 1
      // 	}&report_type=${value}&startDate=${startDate}&endDate=${endDate}&searchText=${keyword}&branchCode=${branchCode}&page_size=${pageSize}`,
      url: `${process.env.REACT_APP_BACKEND_URL
        }GetCustomersBalance/?page=${page ? page : 1
        }&startDate=${startDate}&endDate=${endDate}&searchText=${keyword}&branchCode=${branchCode}&page_size=${pageSize}&order=${order === "asc" ? order : "dsc"
        }`,
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then((res) => {
        dispatch(
          getCustomerSummaryEntrySuccess(
            res.data.results,
            res.data.page,
            res.data.total
          )
        );
        dispatch(ReportsLoadingBool(false));
      })
      .catch((res) => {
        dispatch(ReportsLoadingBool(false));
      });
  };
}
export const GET_CUSTOMER_SUMMARY_ENTRIES = "GET_CUSTOMER_SUMMARY_ENTRIES";
export const getCustomerSummaryEntrySuccess = (data, page, total) => ({
  type: GET_CUSTOMER_SUMMARY_ENTRIES,
  payload: {
    data,
    page,
    total,
  },
});

//Get single customer entries
export function getSingleCustomerEntries(
  page,
  value,
  startDate,
  endDate,
  id,
  branchCode,
  insidePageSize
) {
  return (dispatch) => {
    dispatch(ReportsLoadingBool(true));
    let token = getCookie("authToken");
    Axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}getDataEntry/?page=${page ? page : 1
        }&page_size=${insidePageSize ? insidePageSize : 10
        }&report_type=getSingleCustomerAllEntries&startDate=${startDate}&endDate=${endDate}&searchText=${id}&branchCode=${branchCode}`,
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then((res) => {
        dispatch(
          getSingleCustomerEntriesSuccess(
            res.data.results,
            res.data.page,
            res.data.total,
            res.data.summaryObj
          )
        );
        dispatch(ReportsLoadingBool(false));
      })
      .catch((res) => {
        dispatch(ReportsLoadingBool(false));
      });
  };
}
export const GET_SINGLE_CUSTOMER_ENTRIES = "GET_SINGLE_CUSTOMER_ENTRIES";
export const getSingleCustomerEntriesSuccess = (
  data,
  page,
  total,
  summaryObj
) => ({
  type: GET_SINGLE_CUSTOMER_ENTRIES,
  payload: {
    data,
    page,
    total,
    summaryObj,
  },
});

//Get services entries
export function getServicesEntries(page, value, startDate, endDate, keyword, branchCode, pageSize) {
  return (dispatch) => {
    dispatch(ReportsLoadingBool(true));
    let token = getCookie("authToken");
    Axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}dataEntry/?page=${page ? page : 1
        }&report_type=${value}&start_date=${startDate}&end_date=${endDate}&searchText=${keyword}&branchCode=${branchCode}&page_size=${pageSize}`,
      headers: {
        Authorization: `JWT ${token}`
      }
    })
      .then((res) => {
        console.log("service report check", res.data.results);
        dispatch(getServicesEntriesSuccess(res.data.results, res.data.page, res.data.total, res.data.footer));
        dispatch(ReportsLoadingBool(false));
      })
      .catch((res) => {
        dispatch(ReportsLoadingBool(false));
      });
  };
}
export const GET_SERVICES_ENTRIES = "GET_SERVICES_ENTRIES";
export const getServicesEntriesSuccess = (data, page, total, footer) => ({
  type: GET_SERVICES_ENTRIES,
  payload: {
    data,
    page,
    total,
    footer
  }
});

//Get all expense name
export function getAllExpenseNames(branchCode) {
  return (dispatch) => {
    dispatch(ReportsLoadingBool(true));
    let token = getCookie("authToken");
    Axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}Allexpenses/?branchCode=${branchCode}`,
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then((res) => {
        dispatch(getAllExpenseNamesSuccess(res.data));
        dispatch(ReportsLoadingBool(false));
      })
      .catch((res) => {
        dispatch(ReportsLoadingBool(false));
      });
  };
}
export const GET_ALL_EXPENSE_NAMES = "GET_ALL_EXPENSE_NAMES";
export const getAllExpenseNamesSuccess = (data) => ({
  type: GET_ALL_EXPENSE_NAMES,
  payload: {
    data,
  },
});

//Get getAllAccountEntries entries
export function getAllAccountEntries(
  page,
  value,
  startDate,
  endDate,
  keyword,
  type,
  name,
  branchCode,
  pageSize,
  userType,
  searchText
) {
  return (dispatch) => {
    dispatch(ReportsLoadingBool(true));
    let token = getCookie("authToken");
    // console.log(	page,
    // 	value,
    // 	startDate,
    // 	endDate,
    // 	keyword,
    // 	type,
    // 	name,
    // 	branchCode,
    // 	pageSize,
    // 	userType,
    // 	searchText)
    Axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}getDataEntry/?page=${page ? page : 1
        }&report_type=${value}&startDate=${startDate}&endDate=${endDate}&searchText=${keyword}&type=${type}&name=${name}&branchCode=${branchCode}&page_size=${pageSize}${userType ? `&userType=${userType}` : ''}&allSearch=${searchText}`,
      headers: {
        Authorization: `JWT ${token}`
      }
    })
      .then((res) => {
        console.log(res, 'sadasd')
        dispatch(getAllAccountEntriesSuccess(res.data.results, res.data.page, res.data.total, res.data.page_size, res.data.footer));
        dispatch(ReportsLoadingBool(false));
      })
      .catch((res) => {
        dispatch(ReportsLoadingBool(false));
      });
  };
}

//Get getAllAccountEntries entries
export function getAllAccountRecieveble(
  page,
  value,
  startDate,
  endDate,
  keyword,
  type,
  name,
  branchCode,
  pageSize
) {
  return (dispatch) => {
    dispatch(ReportsLoadingBool(true));
    let token = getCookie("authToken");
    Axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}GetTotalReceivables/?page=${page ? page : 1
        }&report_type=${value}&startDate=${startDate}&endDate=${endDate}&searchText=${keyword}&type=${type}&name=${name}&branchCode=${branchCode}&page_size=${pageSize}`,
      headers: {
        Authorization: `JWT ${token}`
      }
    })
      .then((res) => {
        console.log(res.data, 'sadasd');
        dispatch(getAllAccountEntriesSuccess(res.data.data.hello));
        dispatch(ReportsLoadingBool(false));
      })
      .catch((res) => {
        dispatch(ReportsLoadingBool(false));
      });
  };
}

export const GET_ALL_ACCOUNT_ENTRIES = "GET_ALL_ACCOUNT_ENTRIES";
export const getAllAccountEntriesSuccess = (data, page, total, pageSize, footer) => ({
  type: GET_ALL_ACCOUNT_ENTRIES,
  payload: {
    data, page, total, pageSize, footer
  }
});

//Delete account entries
export function deleteAccountEntry(
  id,
  page,
  value,
  startDate,
  endDate,
  keyword,
  type,
  name,
  branchCode,
  pageSize
) {
  return (dispatch) => {
    dispatch(ReportsLoadingBool(true));
    let token = getCookie("authToken");
    Axios({
      method: "delete",
      url: `${process.env.REACT_APP_BACKEND_URL}expensesEntry/${id}/?branchCode=${branchCode}`,
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then((res) => {
        message.success("Entry deleted");
        dispatch(ReportsLoadingBool(false));
        dispatch(
          getAllAccountEntries(
            page,
            value,
            startDate,
            endDate,
            keyword,
            type,
            name,
            branchCode,
            pageSize
          )
        );
      })
      .catch((res) => {
        dispatch(ReportsLoadingBool(false));
      });
  };
}

//add appraisal report
export function addAppraisalReport(data, back) {
  return (dispatch) => {
    dispatch(ReportsLoadingBool(true));
    let token = getCookie("authToken");
    Axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}appraisalReportApi/`,
      headers: {
        Authorization: `JWT ${token}`,
      },
      data,
    })
      .then((res) => {
        message.success("Appraisal Report Added");
        back();
        // message.success("Entry deleted successfully...")
        dispatch(ReportsLoadingBool(false));
      })
      .catch((res) => {
        dispatch(ReportsLoadingBool(false));
      });
  };
}

//update appraisal report
export function updateAppraisalReport(data, id, back) {
  return (dispatch) => {
    dispatch(ReportsLoadingBool(true));
    let token = getCookie("authToken");
    Axios({
      method: "put",
      url: `${process.env.REACT_APP_BACKEND_URL}appraisalReportApi/${id}/`,
      headers: {
        Authorization: `JWT ${token}`,
      },
      data,
    })
      .then((res) => {
        message.success("Appraisal Report Updated");
        back();
        // message.success("Entry deleted successfully...")
        dispatch(ReportsLoadingBool(false));
      })
      .catch((res) => {
        dispatch(ReportsLoadingBool(false));
      });
  };
}

//add jewellery certificate
export function addJewelleryCertificate(data, back) {
  return (dispatch) => {
    dispatch(ReportsLoadingBool(true));
    let token = getCookie("authToken");
    Axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}jewelleryReportApi/`,
      headers: {
        Authorization: `JWT ${token}`,
      },
      data,
    })
      .then((res) => {
        message.success("Jewellery Certificate Added");
        back();
        dispatch(ReportsLoadingBool(false));
      })
      .catch((res) => {
        dispatch(ReportsLoadingBool(false));
      });
  };
}

//update jewellery certificate
export function updateJewelleryCertificate(data, id, back) {
  return (dispatch) => {
    dispatch(ReportsLoadingBool(true));
    let token = getCookie("authToken");
    Axios({
      method: "put",
      url: `${process.env.REACT_APP_BACKEND_URL}jewelleryReportApi/${id}/`,
      headers: {
        Authorization: `JWT ${token}`,
      },
      data,
    })
      .then((res) => {
        message.success("Jewellery Certificate Updated");
        back();
        dispatch(ReportsLoadingBool(false));
      })
      .catch((res) => {
        dispatch(ReportsLoadingBool(false));
      });
  };
}
